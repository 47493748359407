import React, { useReducer } from "react";
import { ProfilePicture } from "./ProfilePicture";
import {
  AlertFilled,
  AlertOnFilled,
  ArrowDownloadFilled,
  SignOutRegular,
  AutosumRegular,
  HomeRegular,
  NotebookAddRegular,
  PersonRegular,
} from "@fluentui/react-icons";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import {
  Link,
  Outlet,
  useNavigate,
  useRouteContext,
} from "@tanstack/react-router";
import { useUsers } from "@/hooks/users.ts";
import { UseRegisterSW } from "@/types/sw.ts";
import { cn, supabase } from "@/utils";
import { RevisionComponent } from "@/components/RevisionComponent.tsx";

const NotificationInfo: React.FC<{ className?: string }> = (_) => {
  const forceUpdate = useReducer((x) => x + 1, 0)[1];

  const __notification = window.Notification;
  // API unavailable
  if (!__notification || typeof __notification === "undefined") {
    return <></>;
  }

  const permission = __notification.permission;

  if (permission === "granted") {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              className="h-10 w-10 text-green-700 "
              size="icon"
              variant="link"
            >
              <AlertOnFilled className="h-6 w-6" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Notifications are enabled!</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  const requestPermissions = async () => {
    try {
      await __notification.requestPermission();
    } catch (e) {
      console.error("Error requesting notification permissions", e);
    }

    forceUpdate();
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            className="h-10 w-10 text-green-700 "
            size="icon"
            variant="ghost"
            onClick={requestPermissions}
          >
            <AlertFilled
              className={cn("size-6 text-lg", {
                "text-red-600": permission === "denied",
                "text-amber-400": permission !== "denied",
              })}
            />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>Enable notifications</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const LoggedInHeader: React.FC<{
  sw: UseRegisterSW;
}> = ({ sw }) => {
  const needRefresh = sw.needRefresh[0];

  return (
    <div className="flex justify-center gap-2 items-center absolute right-4 top-4">
      {needRefresh ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                onClick={async () => await sw.updateServiceWorker(true)}
              >
                <ArrowDownloadFilled />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Update available</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : null}
    </div>
  );
};

export const AuthenticatedAppView: React.FC = () => {
  const { sw, session } = useRouteContext({ from: "/_authenticated" });

  const userId = session.user.id;
  const users = useUsers();
  const user = users.findById(userId)!;
  const navigate = useNavigate();

  return (
    <section className="h-max min-h-screen flex flex-auto flex-col self-start">
      <header className="px-14 h-16 py-1 text-3xl bg-background border-b sticky top-0 z-10">
        <div className="max-w-screen-md mx-auto">
          <h1 className="my-0 font-medium">
            <Link
              to="/"
              className="flex flex-row flex-nowrap items-center justify-center sm:justify-start gap-2 hover:text-inherit"
            >
              <img
                height={48}
                width={48}
                src="/logo-dixie-48.webp"
                alt="Butastic Logo"
              />
              <span>Butastic</span>
            </Link>
          </h1>
          <LoggedInHeader sw={sw} />
        </div>
      </header>
      <main className="pt-4 px-4 sm:px-0 flex-auto max-w-screen-md w-full sm:w-[calc(100%-7rem)] mx-auto">
        <Outlet />
      </main>
      <footer className="mt-4 p-2 pb-4 sm:pb-2 border-t bg-background sticky bottom-0">
        <div className="max-w-screen-md mx-auto grid grid-cols-5 justify-items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="col-start-1" asChild>
                <Button
                  className="h-10 w-10 text-white"
                  size="icon"
                  variant="link"
                  asChild
                >
                  <Link to="/">
                    <HomeRegular className="h-6 w-6" />
                  </Link>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Home</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <NotificationInfo className="col-start-2" />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="col-start-3" asChild>
                <Button
                  className="h-10 w-10 text-white"
                  size="icon"
                  variant="link"
                  asChild
                >
                  <Link to="/add">
                    <NotebookAddRegular className="h-6 w-6" />
                  </Link>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Neuer Eintrag</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="col-start-4" asChild>
                <Button
                  className="h-10 w-10 text-white"
                  size="icon"
                  variant="link"
                  asChild
                >
                  <Link to="/sum">
                    <AutosumRegular className="h-6 w-6" />
                  </Link>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Kassensturz</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <DropdownMenu>
            <DropdownMenuTrigger className="col-start-5">
              <ProfilePicture size={40} userId={userId} />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="text-xl">
              <DropdownMenuLabel>Hallo {user.displayName}!</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem asChild>
                <Link to="/settings/profile" className="cursor-pointer">
                  <PersonRegular /> Profil
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={async () => {
                  await supabase.auth.signOut({ scope: "global" });
                  await navigate({ to: "/login" });
                }}
              >
                <SignOutRegular className="text-red-700" /> Logout
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>
                <RevisionComponent />
              </DropdownMenuLabel>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </footer>
    </section>
  );
};
