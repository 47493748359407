import { createFileRoute, redirect } from "@tanstack/react-router";
import { supabase } from "@/utils";
import { ButasticTrpcUtils, trpc } from "@/types/backend.ts";
import { queryClient } from "@/utils/queryClient.ts";
import { httpLink } from "@trpc/client";
import superjson from "superjson";
import { createTRPCQueryUtils } from "@trpc/react-query";
import { AuthenticatedAppView } from "@/components/AuthenticatedAppView.tsx";
import { atom } from "jotai";

const url = new URL(import.meta.env.VITE_BACKEND_URL.replace(/[\/]+$/, ""));

const accessTokenAtom = atom<string>();

const clientAtom = atom((get) => {
  const accessToken = get(accessTokenAtom);
  if (!accessToken) {
    throw new Error("Access token not set");
  }
  return trpc.createClient({
    links: [
      httpLink({
        url,
        transformer: superjson,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    ],
  });
});

export const Route = createFileRoute("/_authenticated")({
  beforeLoad: async ({ context, location, search }) => {
    const { data, error } = await supabase.auth.getSession();
    if (!!error) {
      throw error;
    }
    const session = data.session;

    if (!session) {
      throw redirect({
        to: "/login",
        search: { ...search, redirectUrl: location.href },
      });
    }

    const accessToken = session.access_token;

    context.store.set(accessTokenAtom, accessToken);

    const trpcClient = context.store.get(clientAtom);

    const trpcUtils: ButasticTrpcUtils = createTRPCQueryUtils({
      queryClient,
      client: trpcClient,
    });

    const users = await trpcUtils.listAllUsers.ensureData();

    return { session, trpcClient, trpcUtils, users };
  },
  component: AuthenticatedApp,
});

export function AuthenticatedApp() {
  const { trpcClient } = Route.useRouteContext();

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <AuthenticatedAppView />
    </trpc.Provider>
  );
}
