import { useMemo } from "react";
import { trpc } from "@/types/backend.ts";
import type { DisplayUser } from "butastic-backend/src/types.ts";

export interface Users extends Array<DisplayUser> {
  findById: (id: string) => DisplayUser | undefined;

  get userIds(): string[];
}

export const useUsers = () => {
  const [data] = trpc.listAllUsers.useSuspenseQuery();

  const typed = data as DisplayUser[];

  return useMemo(
    () =>
      !!typed
        ? (Object.assign(typed, {
            findById: (id: string) => typed.find((it) => it.id === id),
            userIds: typed.map((it) => it.id),
          }) as Users)
        : typed,
    [typed]
  );
};
