import * as React from "react";

import { cn } from "@/utils";
import { FieldApi } from "@tanstack/react-form";
import { ChangeEventHandler, useCallback } from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  field?: FieldApi<any, any>;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, field, name, onChange, onBlur, ...props }, ref) => {
    const computedName = field?.name ?? name;
    const computedChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      !!field ? (e) => field.handleChange(e.target.value) : () => {},
      [field]
    );
    const computedBlur = field?.handleBlur ?? onBlur;

    return (
      <input
        type={type}
        className={cn(
          "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className,
          !!field
            ? { "border-red-600/60": field.state.meta.errors.length > 0 }
            : undefined
        )}
        ref={ref}
        {...props}
        name={computedName}
        onChange={!!field ? computedChange : onChange}
        onBlur={computedBlur}
        value={field?.state.value}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
