import { createFileRoute, redirect, useNavigate } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/Spinner";
import logo from "/logo.svg?raw";
import { RevisionComponent } from "@/components/RevisionComponent.tsx";
import { supabase } from "@/utils";
import React, { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { SignInWithPasswordCredentials } from "@supabase/supabase-js";
import { z } from "zod";

const searchSchema = z.object({
  redirectUrl: z.string().optional(),
});

export const Route = createFileRoute("/login")({
  component: LoginForm,
  validateSearch: searchSchema,
  beforeLoad: async ({ search }) => {
    let sessionResult;
    try {
      sessionResult = await supabase.auth.getSession();
    } catch {
      // ignore
    }

    if (!!sessionResult?.data?.session) {
      throw redirect({ to: search.redirectUrl ?? "/" });
    }
  },
});

export function LoginForm() {
  const navigate = useNavigate();

  const loginMutation = useMutation({
    mutationFn: async (credentials: SignInWithPasswordCredentials) => {
      const result = await supabase.auth.signInWithPassword(credentials);
      console.log("result", result);
      if (!!result.error) {
        throw result.error;
      }

      return result.data;
    },
  });

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      await loginMutation.mutateAsync(
        Object.fromEntries(
          new FormData(e.currentTarget).entries()
        ) as unknown as SignInWithPasswordCredentials
      );
      await navigate({ to: "/" });
    },
    [loginMutation, navigate]
  );

  return (
    <div className="h-screen w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="relative lg:h-full flex-col p-10 text-white dark:border-r lg:flex bg-transparent lg:bg-zinc-900 justify-between">
        <div className="relative z-20 flex items-center text-lg font-medium justify-center lg:justify-start">
          Butastic
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: logo }}
          className="max-h-32 lg:max-h-96 h-[50vw] z-20 *:!stroke-[1rem]"
        />
        <RevisionComponent classNames="hidden lg:block" />
      </div>
      <div className="flex flex-col items-center justify-center pb-12 pt-2 lg:pt-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Login</h1>
            <p className="text-balance text-muted-foreground">
              Enter your email below to login to your account
            </p>
          </div>
          <form className="grid gap-4" onSubmit={handleSubmit}>
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="you@example.com"
                required
              />
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="password">Password</Label>
              </div>
              <Input id="password" name="password" type="password" required />
            </div>
            <Button
              type="submit"
              className="w-full"
              disabled={loginMutation.isPending}
            >
              {loginMutation.isPending ? (
                <Spinner size="small" label="Login" />
              ) : (
                "Login"
              )}
            </Button>
            {loginMutation.status === "error" ? (
              <span className="text-red-600">
                {loginMutation.error.message}
              </span>
            ) : null}
          </form>
          <div className="mt-4 text-center text-sm">
            Don&apos;t have an account?{" "}
          </div>
        </div>
        <RevisionComponent classNames="block lg:hidden absolute bottom-8" />
      </div>
    </div>
  );
}
