import { ProfilePicture } from "@/components/ProfilePicture.tsx";
import { cn } from "@/utils";

export function AvatarStack(props: { ids: string[] }) {
  return (
    <div role="group" className="inline-flex">
      {props.ids.map((it, index) => (
        <ProfilePicture
          key={it}
          size={32}
          userId={it}
          shape="circle"
          className={cn(
            "relative shrink-0 inline-flex items-center shadow shadow-1 ring-background ring-1",
            {
              "-ml-1.5": index > 0,
            }
          )}
        />
      ))}
    </div>
  );
}
