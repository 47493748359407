import { createFileRoute, Link, Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/settings")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <div className="flex flex-col gap-4">
      <div className="mx-auto grid w-full max-w-6xl gap-2">
        <h1 className="text-3xl font-semibold">Einstellungen</h1>
      </div>
      <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
        <nav
          className="grid gap-4 text-sm text-muted-foreground"
          x-chunk="dashboard-04-chunk-0"
        >
          <Link
            to="/settings/profile"
            activeProps={{ className: "font-semibold text-primary" }}
          >
            Profil
          </Link>
          <Link
            to="/settings/security"
            activeProps={{ className: "font-semibold text-primary" }}
          >
            Sicherheit
          </Link>
        </nav>
        <Outlet />
      </div>
    </div>
  );
}
