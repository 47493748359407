import { getRouteApi } from "@tanstack/react-router";
import React, { useMemo } from "react";
import type { Entry } from "butastic-backend/src/queries/entry.ts";
import { Separator } from "@/components/ui/separator.tsx";
import { MoreVerticalRegular as MoreVertical } from "@fluentui/react-icons";
import { Button as ShdButton } from "@/components/ui/button.tsx";
import { SheetDescription, SheetHeader } from "@/components/ui/sheet.tsx";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { trpc } from "@/types/backend.ts";
import { AvatarStack } from "@/components/AvatarStack.tsx";
import { ProfilePicture } from "@/components/ProfilePicture.tsx";
import { Spinner } from "@/components/Spinner.tsx";

const SumDisplay: React.FC<{ positions: Entry["positions"] }> = ({
  positions,
}) => {
  const total = useMemo(() => {
    return positions.reduce(
      (state, position) => (position.price ? state + position.price : state),
      0
    );
  }, [positions]);

  return <>{total.toFixed(2)} €</>;
};

const Route = getRouteApi("/_authenticated/");

export function EntryDetails(props: { entryId: string }) {
  const { entryId } = props;

  const initialLoadData = Route.useLoaderData().find(
    (it) => it.id === entryId
  )!;

  const query = trpc.getEntry.useQuery(entryId);

  const entry: Entry | null | undefined = query.data;

  console.log("Initial", initialLoadData, "Entry", entry);

  return (
    <SheetHeader className="pt-4">
      <SheetHeader className="hidden">{initialLoadData.name}</SheetHeader>
      <SheetDescription>
        <Card className="overflow-hidden">
          <CardHeader className="flex flex-row items-start bg-muted/50">
            <div className="grid gap-0.5">
              <CardTitle className="group flex items-center text-lg">
                {initialLoadData.name}
              </CardTitle>
              <CardDescription className="text-left flex flex-col">
                <div>
                  Datum:{" "}
                  {initialLoadData.date.toLocaleDateString("de-de", {
                    weekday: "short",
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hourCycle: "h24",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
                <div>
                  <AvatarStack ids={(entry ?? initialLoadData).paidBy} />
                </div>
              </CardDescription>
            </div>
            <div className="ml-auto flex items-center gap-1">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <ShdButton size="icon" variant="outline" className="h-8 w-8">
                    <MoreVertical className="h-3.5 w-3.5" />
                    <span className="sr-only">More</span>
                  </ShdButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem>Edit</DropdownMenuItem>
                  <DropdownMenuItem>Export</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Trash</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </CardHeader>
          <CardContent className="p-6 text-sm">
            {query.status === "pending" ? (
              <Spinner label={"Wird geladen"} />
            ) : query.status === "error" ? (
              "Error"
            ) : !entry ? (
              "Not found"
            ) : (
              <div className="grid gap-4 items-center grid-cols-[1fr_max-content_max-content]">
                {entry.positions.map((position, index) => (
                  <div className="grid grid-cols-subgrid col-span-3">
                    <div
                      key={index * 3}
                      className="justify-self-start text-muted-foreground max-w-full overflow-hidden whitespace-nowrap text-ellipsis"
                    >
                      {position.description}
                    </div>
                    <div
                      key={index * 3 + 1}
                      className="flex flex-row flex-nowrap gap-1"
                    >
                      {position.paidFor.map((userId) => (
                        <ProfilePicture
                          key={userId}
                          size={32}
                          userId={userId}
                          shape="circle"
                        />
                      ))}
                    </div>
                    <div key={index * 3 + 2} className="justify-self-end">
                      {position.price.toFixed(2)} €
                    </div>
                  </div>
                ))}

                <Separator className="my-4 col-span-4" />

                <div className="grid grid-cols-subgrid col-span-3 font-bold w-full">
                  <span className="col-start-1 col-span-2 justify-self-start text-muted-foreground">
                    Total
                  </span>
                  <div className="col-start-3 justify-self-end">
                    <SumDisplay positions={entry.positions} />
                  </div>
                </div>
              </div>
            )}
          </CardContent>
          <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3 hidden">
            <div className="text-xs text-muted-foreground">TODO</div>
          </CardFooter>
        </Card>
      </SheetDescription>
    </SheetHeader>
  );
}
