import React from "react";
import { EntryDisplay } from "@/routes/_authenticated/index/-components/EntryDisplay.tsx";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { z } from "zod";
import { Separator } from "@/components/ui/separator";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { EntryDetails } from "@/routes/_authenticated/index/-components/EntryDetails.tsx";
import type { OverviewEntry } from "butastic-backend/src/queries/entry";
import type { OverviewCashup } from "butastic-backend/src/queries/cashUp";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Button } from "@/components/ui/button.tsx";
import { ChevronRightFilled } from "@fluentui/react-icons";
import { SumEntries, SumEntry } from "@/components/SumEntry.tsx";

const searchSchema = z.object({ selectedEntry: z.string().optional() });

const IndexPage: React.FC = () => {
  const { entries, cashups } = Route.useLoaderData();
  const { selectedEntry: selectedEntryId } = Route.useSearch();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-2">
      <Tabs defaultValue="active">
        <div className="flex items-center">
          <TabsList>
            <TabsTrigger value="active">Aktiv</TabsTrigger>
            <TabsTrigger value="archived">Archiviert</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="active">
          <div className="grid gap-y-2 gap-x-4  items-center auto-rows-auto mt-6 grid-cols-[1fr_max-content_max-content] sm:grid-cols-[1fr_max-content_max-content_max-content]">
            <div key="name" className="justify-self-start px-1">
              Name
            </div>
            <div key="date" className="justify-self-start hidden sm:block">
              Datum
            </div>
            <div key="paidBy" className="justify-self-start">
              Bezahlt
            </div>
            <div key="total" className="justify-self-end">
              Total
            </div>
            <Separator strokeWidth="thick" className="col-span-4" />
            {entries.map((entry, index) => (
              <EntryDisplay key={index} entry={entry} index={index} />
            ))}
          </div>
        </TabsContent>
        <TabsContent value="archived" className="flex flex-col gap-3 mt-4">
          {cashups.length > 0 ? (
            cashups.map((cashUp) => (
              <Card className="overflow-hidden">
                <CardHeader className="flex flex-row items-start py-4">
                  <div className="grid gap-0.5">
                    <CardTitle className="group flex items-center text-lg">
                      <div>
                        Kassensturz:{" "}
                        {cashUp.date.toLocaleDateString("de-de", {
                          weekday: "short",
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hourCycle: "h24",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </div>
                    </CardTitle>
                    <CardDescription className="text-left flex flex-col">
                      <div>{cashUp.cashUpId}</div>
                    </CardDescription>
                  </div>
                  <div className="ml-auto my-auto flex items-center gap-1">
                    <Button variant="ghost">
                      <ChevronRightFilled />
                    </Button>
                  </div>
                </CardHeader>
                <CardContent className="py-4 bg-muted/60">
                  <SumEntries>
                    {cashUp.balances.map((balance) => (
                      <SumEntry {...balance} />
                    ))}
                  </SumEntries>
                </CardContent>
              </Card>
            ))
          ) : (
            <h3>Keine Daten</h3>
          )}
        </TabsContent>
      </Tabs>
      <Sheet
        open={!!selectedEntryId}
        onOpenChange={async (opened) => {
          if (!opened) {
            await navigate({ to: ".", search: {} });
          }
        }}
      >
        <SheetContent className="w-screen sm:w-3/4 sm:max-w-lg">
          {!!selectedEntryId ? (
            <EntryDetails entryId={selectedEntryId} />
          ) : null}
        </SheetContent>
      </Sheet>
    </div>
  );
};

export const Route = createFileRoute("/_authenticated/")({
  component: IndexPage,
  validateSearch: searchSchema,
  loader: async ({ context }) => {
    const entries =
      (await context.trpcUtils.getAllEntries.ensureData()) as OverviewEntry[];
    const cashups =
      (await context.trpcUtils.listCashups.ensureData()) as OverviewCashup[];

    return { entries, cashups };
  },
});
