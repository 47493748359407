import React from "react";
import { Link } from "@tanstack/react-router";
import { AvatarStack } from "@/components/AvatarStack.tsx";
import type { OverviewEntry } from "butastic-backend/src/queries/entry.ts";

export const EntryDisplay: React.FC<{
  entry: OverviewEntry;
  index: number;
}> = ({ entry, index }) => {
  return (
    <Link
      to={"/"}
      search={{ selectedEntry: entry.id }}
      className="grid grid-cols-subgrid col-start-1 col-span-4 items-center text-[inherit] peer peer-[&]:border-t peer-[&]:pt-2"
    >
      <div key={index * 4} className="justify-self-start px-1">
        {entry.name}
      </div>
      <div key={index * 4 + 1} className="px-1 hidden sm:block">
        {entry.date.toLocaleDateString("de-de", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}
      </div>
      <div key={index * 4 + 2} className="flex flex-row flex-nowrap gap-1">
        <AvatarStack ids={entry.paidBy} />
      </div>
      <div key={index * 4 + 3} className="justify-self-end">
        <>{entry.positionsTotal.toFixed(2)} €</>
      </div>
    </Link>
  );
};
