import React, { PropsWithChildren } from "react";
import { useUsers } from "@/hooks/users.ts";
import { ProfilePicture } from "@/components/ProfilePicture.tsx";
import { cn } from "@/utils";

export const SumEntries = (props: PropsWithChildren) => {
  return (
    <div className="grid gap-2 items-center grid-cols-[max-content_max-content_1rem_max-content]">
      {props.children}
    </div>
  );
};

export const SumEntry: React.FC<{ userId: string; balance: number }> = ({
  userId,
  balance,
}) => {
  const users = useUsers();
  const displayName = users.findById(userId)!.displayName;
  return (
    <>
      <ProfilePicture key={userId + "0"} size={40} userId={userId} />
      <span key={userId + "1"}>{displayName}</span>
      <span
        key={userId + "2"}
        className={cn("col-start-4 justify-self-end", {
          "text-green-500": balance > 0,
          "text-red-500": balance < 0,
          "text-gray-600": balance === 0,
        })}
      >
        {balance.toFixed(2)}€
      </span>
    </>
  );
};
