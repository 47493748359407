import React, { ReactNode } from "react";
import { Button } from "@/components/ui/button";
import { ArrowCounterclockwiseFilled } from "@fluentui/react-icons";
import { RevisionComponent } from "@/components/RevisionComponent.tsx";

const StackTrace: React.FC<{ stack: string | undefined }> = ({ stack }) => {
  if (!stack) {
    return <>{"(None)"}</>;
  }

  return (
    <ul className="list-none">
      {stack.split("\n").map((entry) => (
        <li>{entry}</li>
      ))}
    </ul>
  );
};

export class ErrorBoundary extends React.Component<
  { children?: ReactNode },
  {
    hasError: boolean;
    error: Error | undefined;
    errorInfo: { componentStack: string } | undefined;
  }
> {
  constructor(props: { children?: ReactNode }) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    console.error("Fatal error", error, errorInfo);
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="block text-left text-white flex-col bg-muted/40">
          <h1 className="text-center">Something went wrong.</h1>
          <div>
            <span>Error:</span>
            {this.state.error !== undefined ? (
              <ul className="list-none">
                <li>Name: {this.state.error.name}</li>
                <li>Message: {this.state.error.message}</li>
                <li>
                  Stack: <StackTrace stack={this.state.error.stack} />
                </li>
              </ul>
            ) : (
              <span>(None)</span>
            )}
          </div>
          <div>
            <span>Error Info: </span>
            <StackTrace stack={this.state.errorInfo?.componentStack} />
          </div>
          <div className="flex space-x-2 justify-center">
            <Button
              onClick={() => window.location.reload()}
              icon={<ArrowCounterclockwiseFilled />}
            >
              Reload page
            </Button>
          </div>
          <RevisionComponent />
        </div>
      );
    }

    return this.props.children;
  }
}
