import { FC, ComponentProps, useCallback, useRef } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAvatarProps, AvatarProps } from "@/components/useAvatarProps.ts";

type ImageLoadingStatus = Parameters<
  Required<ComponentProps<typeof AvatarImage>>["onLoadingStatusChange"]
>[0];

export const ProfilePicture: FC<
  AvatarProps & {
    shape?: "circle" | "square";
    onLoaded?: (src: string | undefined) => void;
    onError?: () => void;
  }
> = (props) => {
  const { shape, onError, onLoaded } = props;

  const imgRef = useRef<HTMLImageElement | null>(null);
  const avatarProps = useAvatarProps(props);

  const onLoadingStatusChange = useCallback((status: ImageLoadingStatus) => {
    if (status === "loaded") {
      onLoaded?.(imgRef.current?.src);
    } else if (status === "error") {
      onError?.();
    }
  }, []);

  return (
    <Avatar
      shape={shape ?? "square"}
      style={{ width: avatarProps.size, height: avatarProps.size }}
      className={avatarProps.className}
    >
      <AvatarImage
        ref={imgRef}
        {...avatarProps.image}
        onLoadingStatusChange={onLoadingStatusChange}
      />
      <AvatarFallback>{avatarProps.name}</AvatarFallback>
    </Avatar>
  );
};
