import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";

import { cn } from "@/utils";

type Props = React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> & {
  strokeWidth?: "thin" | "thick";
};

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  Props
>(
  (
    {
      className,
      orientation = "horizontal",
      decorative = true,
      strokeWidth = "thin",
      ...props
    },
    ref
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(
        "shrink-0 bg-border",
        orientation === "horizontal" ? "w-full" : "h-full",
        {
          "h-thin": strokeWidth === "thin" && orientation === "horizontal",
          "w-thin": strokeWidth === "thin" && orientation !== "horizontal",

          "h-thick": strokeWidth === "thick" && orientation === "horizontal",
          "w-thick": strokeWidth === "thick" && orientation !== "horizontal",
        },
        className
      )}
      {...props}
    />
  )
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
