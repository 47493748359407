import { cn } from "@/utils";
import { useId } from "react";

type Sizes = "small" | "medium";

type SpinnerProps = { size?: Sizes; label?: string };

export function Spinner(props: SpinnerProps) {
  const labelId = useId();
  const size = props.size || "medium";

  return (
    <div
      role="progressbar"
      className="flex items-center justify-center leading-[0] gap-2 overflow-hidden"
      aria-labelledby={props.label ? labelId : undefined}
    >
      <span
        className={cn("spinner", {
          "spinner-sm": size === "small",
          "spinner-md": size === "medium",
        })}
      >
        <span className="spinner-tail"></span>
      </span>
      {props.label ? <label id={labelId}>{props.label}</label> : null}
    </div>
  );
}
