import { useUsers } from "@/hooks/users.ts";
import { supabase } from "@/utils";
import { useMemo } from "react";

export type AvatarProps = {
  size: 32 | 40 | 48 | 64;
  className?: string;
  userId: string;
};
export const useAvatarProps = (props: AvatarProps) => {
  const { size, className, ...userProps } = props;
  const userId = userProps.userId;

  const users = useUsers();
  const user = users.findById(userId)!;
  if (!user) {
    console.log("user not found", userId, className, users);
  }
  const pictureUrl = useMemo(
    () =>
      supabase.storage.from("profile-pictures").getPublicUrl(userId, {
        transform: {
          height: 64,
          width: 64,
        },
      }).data.publicUrl,
    [userId]
  );

  return {
    size,
    image: {
      src: pictureUrl,
      crossOrigin: "anonymous" as const,
    },
    className,
    name: user.displayName,
  };
};
