import { cn } from "@/utils";

export function RevisionComponent(props: { classNames?: string }) {
  const refName = import.meta.env.GITHUB_REF_NAME;
  return (
    <span className={cn("text-muted", props.classNames)}>
      Revision: {!!refName ? `${refName}-` : null}
      {import.meta.env.GITHUB_SHA?.substring(0, 8) || "local"}
    </span>
  );
}
