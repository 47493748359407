import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import type { QueryClient } from "@tanstack/react-query";
import type { UseRegisterSW } from "@/types/sw.ts";
import type { SupabaseClient } from "@supabase/supabase-js";
import type { Store } from "@/utils";
import { Spinner } from "@/components/Spinner.tsx";
import { lazy } from "react";

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null
  : lazy(() =>
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      }))
    );

export type RouterContext = {
  sw: UseRegisterSW;
  queryClient: QueryClient;
  supabase: SupabaseClient<any, "public", any>;
  store: Store;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  notFoundComponent: () => <div>Nothing here...</div>,
  pendingComponent: () => <Spinner label={"Loading..."} />,
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevtools initialIsOpen={false} />
    </>
  ),
});
