import { z } from "zod";

export const formSchema = z
  .object({
    currentPassword: z.string(),
    newPassword: z.string(),
    newPasswordConfirm: z.string(),
  })
  .superRefine(({ newPassword, newPasswordConfirm }, ctx) => {
    if (newPassword != newPasswordConfirm) {
      for (const path of [
        "newPassword",
        "newPasswordConfirm",
      ] satisfies (keyof FormType)[]) {
        ctx.addIssue({
          message: "Passwörter müssen übereinstimmen",
          code: "custom",
          path: [path],
        });
      }
    }
  });

export type FormType = z.infer<typeof formSchema>;
