import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "@/App.tsx";
import { queryClient } from "@/utils/queryClient.ts";
import { ErrorBoundary } from "@/components/ErrorBoundary.tsx";
import { QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster.tsx";

const ReactQueryDevtools = import.meta.env.PROD
  ? () => null
  : React.lazy(() =>
      import("@tanstack/react-query-devtools").then((res) => ({
        default: res.ReactQueryDevtools,
      }))
    );

const rootElement = document.getElementById("root") as HTMLDivElement;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <div className="text-left bg-muted/40">
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
          <App />
          <Toaster />
        </QueryClientProvider>
      </ErrorBoundary>
    </div>
  </React.StrictMode>
);
