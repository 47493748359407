import { createFileRoute } from "@tanstack/react-router";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useForm } from "@tanstack/react-form";
import { formSchema } from "@/routes/_authenticated/settings/security/-utils/formSchema.ts";
import { customToast } from "@/hooks/use-toast.ts";
import { useRef } from "react";

export const Route = createFileRoute("/_authenticated/settings/security")({
  component: RouteComponent,
});

function RouteComponent() {
  const { supabase } = Route.useRouteContext();

  const formRef = useRef<HTMLFormElement>(null);
  const {
    Field: FormField,
    handleSubmit,
    Subscribe,
  } = useForm({
    validators: {
      onSubmit: formSchema,
    },
    onSubmitInvalid: ({ value, formApi }) => {
      console.error("Failed", value, formApi.state.errors);
    },
    onSubmit: async ({ value, formApi }) => {
      const r = await supabase.auth.updateUser({
        password: value.newPassword,
      });

      if (!!r.error) {
        console.error("Error changing password", r.error);

        customToast({
          outcome: "fail",
          description: r.error.message,
        });
      } else {
        console.log("Succeeded changing password", r.data);

        customToast({
          outcome: "success",
          description: "Passwort geändert",
        });

        formRef.current?.reset();
        formApi.reset();
      }

      console.log("Result pw", r);
    },
  });

  return (
    <div className="grid gap-6">
      <form
        ref={formRef}
        onSubmit={async (e) => {
          e.preventDefault();
          await handleSubmit();
        }}
      >
        <Card>
          <CardHeader>
            <CardTitle>Passwort</CardTitle>
            <CardDescription>
              Hier kannst du dein Passwort ändern.
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col gap-4">
            <Label>Aktuelles Passwort</Label>
            <FormField name="currentPassword">
              {(field) => (
                <Input
                  placeholder="Aktuelles Passwort"
                  type="password"
                  field={field}
                />
              )}
            </FormField>
            <Label>Neues Passwort</Label>
            <FormField name="newPassword">
              {(field) => (
                <Input
                  placeholder="Neues Passwort"
                  type="password"
                  field={field}
                />
              )}
            </FormField>
            <FormField name="newPasswordConfirm">
              {(field) => (
                <Input
                  placeholder="Neues Passwort bestätigen"
                  type="password"
                  field={field}
                />
              )}
            </FormField>
            <Subscribe selector={(it) => it.errorMap.onSubmit}>
              {(errors) => {
                console.log("E", errors);
                return typeof errors === "object" && !!errors ? (
                  <ul>
                    {[
                      ...(typeof errors.form === "string"
                        ? errors.form.split(",")
                        : [errors.form]),
                    ].map((error) => (
                      <li>{error}</li>
                    ))}
                    {Object.entries(errors.fields).map(([k, v]) => (
                      <li>
                        {k}: {v}
                      </li>
                    ))}
                  </ul>
                ) : null;
              }}
            </Subscribe>
          </CardContent>
          <CardFooter className="border-t px-6 py-4">
            <Button type="submit">Speichern</Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  );
}
