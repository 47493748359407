import { createRouter } from "@tanstack/react-router";

import { routeTree } from "./routeTree.gen";
import { queryClient } from "@/utils/queryClient.ts";
import { supabase, store } from "@/utils";

export const router = createRouter({
  routeTree,
  context: {
    // We'll be passing down the state from within a React component
    sw: undefined!,
    queryClient,
    supabase,
    store,
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
