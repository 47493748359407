/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as AuthenticatedRouteImport } from './routes/_authenticated/route'
import { Route as AuthenticatedSumRouteImport } from './routes/_authenticated/sum/route'
import { Route as AuthenticatedSettingsRouteImport } from './routes/_authenticated/settings/route'
import { Route as AuthenticatedAddRouteImport } from './routes/_authenticated/add/route'
import { Route as AuthenticatedIndexRouteImport } from './routes/_authenticated/index/route'
import { Route as AuthenticatedSettingsIndexImport } from './routes/_authenticated/settings/index'
import { Route as AuthenticatedSettingsSecurityRouteImport } from './routes/_authenticated/settings/security/route'
import { Route as AuthenticatedSettingsProfileRouteImport } from './routes/_authenticated/settings/profile/route'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRouteRoute = AuthenticatedRouteImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedSumRouteRoute = AuthenticatedSumRouteImport.update({
  id: '/sum',
  path: '/sum',
  getParentRoute: () => AuthenticatedRouteRoute,
} as any).lazy(() =>
  import('./routes/_authenticated/sum/route.lazy').then((d) => d.Route),
)

const AuthenticatedSettingsRouteRoute = AuthenticatedSettingsRouteImport.update(
  {
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AuthenticatedRouteRoute,
  } as any,
)

const AuthenticatedAddRouteRoute = AuthenticatedAddRouteImport.update({
  id: '/add',
  path: '/add',
  getParentRoute: () => AuthenticatedRouteRoute,
} as any).lazy(() =>
  import('./routes/_authenticated/add/route.lazy').then((d) => d.Route),
)

const AuthenticatedIndexRouteRoute = AuthenticatedIndexRouteImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRouteRoute,
} as any)

const AuthenticatedSettingsIndexRoute = AuthenticatedSettingsIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedSettingsRouteRoute,
  } as any,
)

const AuthenticatedSettingsSecurityRouteRoute =
  AuthenticatedSettingsSecurityRouteImport.update({
    id: '/security',
    path: '/security',
    getParentRoute: () => AuthenticatedSettingsRouteRoute,
  } as any)

const AuthenticatedSettingsProfileRouteRoute =
  AuthenticatedSettingsProfileRouteImport.update({
    id: '/profile',
    path: '/profile',
    getParentRoute: () => AuthenticatedSettingsRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedRouteImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexRouteImport
      parentRoute: typeof AuthenticatedRouteImport
    }
    '/_authenticated/add': {
      id: '/_authenticated/add'
      path: '/add'
      fullPath: '/add'
      preLoaderRoute: typeof AuthenticatedAddRouteImport
      parentRoute: typeof AuthenticatedRouteImport
    }
    '/_authenticated/settings': {
      id: '/_authenticated/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthenticatedSettingsRouteImport
      parentRoute: typeof AuthenticatedRouteImport
    }
    '/_authenticated/sum': {
      id: '/_authenticated/sum'
      path: '/sum'
      fullPath: '/sum'
      preLoaderRoute: typeof AuthenticatedSumRouteImport
      parentRoute: typeof AuthenticatedRouteImport
    }
    '/_authenticated/settings/profile': {
      id: '/_authenticated/settings/profile'
      path: '/profile'
      fullPath: '/settings/profile'
      preLoaderRoute: typeof AuthenticatedSettingsProfileRouteImport
      parentRoute: typeof AuthenticatedSettingsRouteImport
    }
    '/_authenticated/settings/security': {
      id: '/_authenticated/settings/security'
      path: '/security'
      fullPath: '/settings/security'
      preLoaderRoute: typeof AuthenticatedSettingsSecurityRouteImport
      parentRoute: typeof AuthenticatedSettingsRouteImport
    }
    '/_authenticated/settings/': {
      id: '/_authenticated/settings/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof AuthenticatedSettingsIndexImport
      parentRoute: typeof AuthenticatedSettingsRouteImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedSettingsRouteRouteChildren {
  AuthenticatedSettingsProfileRouteRoute: typeof AuthenticatedSettingsProfileRouteRoute
  AuthenticatedSettingsSecurityRouteRoute: typeof AuthenticatedSettingsSecurityRouteRoute
  AuthenticatedSettingsIndexRoute: typeof AuthenticatedSettingsIndexRoute
}

const AuthenticatedSettingsRouteRouteChildren: AuthenticatedSettingsRouteRouteChildren =
  {
    AuthenticatedSettingsProfileRouteRoute:
      AuthenticatedSettingsProfileRouteRoute,
    AuthenticatedSettingsSecurityRouteRoute:
      AuthenticatedSettingsSecurityRouteRoute,
    AuthenticatedSettingsIndexRoute: AuthenticatedSettingsIndexRoute,
  }

const AuthenticatedSettingsRouteRouteWithChildren =
  AuthenticatedSettingsRouteRoute._addFileChildren(
    AuthenticatedSettingsRouteRouteChildren,
  )

interface AuthenticatedRouteRouteChildren {
  AuthenticatedIndexRouteRoute: typeof AuthenticatedIndexRouteRoute
  AuthenticatedAddRouteRoute: typeof AuthenticatedAddRouteRoute
  AuthenticatedSettingsRouteRoute: typeof AuthenticatedSettingsRouteRouteWithChildren
  AuthenticatedSumRouteRoute: typeof AuthenticatedSumRouteRoute
}

const AuthenticatedRouteRouteChildren: AuthenticatedRouteRouteChildren = {
  AuthenticatedIndexRouteRoute: AuthenticatedIndexRouteRoute,
  AuthenticatedAddRouteRoute: AuthenticatedAddRouteRoute,
  AuthenticatedSettingsRouteRoute: AuthenticatedSettingsRouteRouteWithChildren,
  AuthenticatedSumRouteRoute: AuthenticatedSumRouteRoute,
}

const AuthenticatedRouteRouteWithChildren =
  AuthenticatedRouteRoute._addFileChildren(AuthenticatedRouteRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedRouteRouteWithChildren
  '/login': typeof LoginRoute
  '/': typeof AuthenticatedIndexRouteRoute
  '/add': typeof AuthenticatedAddRouteRoute
  '/settings': typeof AuthenticatedSettingsRouteRouteWithChildren
  '/sum': typeof AuthenticatedSumRouteRoute
  '/settings/profile': typeof AuthenticatedSettingsProfileRouteRoute
  '/settings/security': typeof AuthenticatedSettingsSecurityRouteRoute
  '/settings/': typeof AuthenticatedSettingsIndexRoute
}

export interface FileRoutesByTo {
  '/login': typeof LoginRoute
  '/': typeof AuthenticatedIndexRouteRoute
  '/add': typeof AuthenticatedAddRouteRoute
  '/sum': typeof AuthenticatedSumRouteRoute
  '/settings/profile': typeof AuthenticatedSettingsProfileRouteRoute
  '/settings/security': typeof AuthenticatedSettingsSecurityRouteRoute
  '/settings': typeof AuthenticatedSettingsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteRouteWithChildren
  '/login': typeof LoginRoute
  '/_authenticated/': typeof AuthenticatedIndexRouteRoute
  '/_authenticated/add': typeof AuthenticatedAddRouteRoute
  '/_authenticated/settings': typeof AuthenticatedSettingsRouteRouteWithChildren
  '/_authenticated/sum': typeof AuthenticatedSumRouteRoute
  '/_authenticated/settings/profile': typeof AuthenticatedSettingsProfileRouteRoute
  '/_authenticated/settings/security': typeof AuthenticatedSettingsSecurityRouteRoute
  '/_authenticated/settings/': typeof AuthenticatedSettingsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/'
    | '/add'
    | '/settings'
    | '/sum'
    | '/settings/profile'
    | '/settings/security'
    | '/settings/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/login'
    | '/'
    | '/add'
    | '/sum'
    | '/settings/profile'
    | '/settings/security'
    | '/settings'
  id:
    | '__root__'
    | '/_authenticated'
    | '/login'
    | '/_authenticated/'
    | '/_authenticated/add'
    | '/_authenticated/settings'
    | '/_authenticated/sum'
    | '/_authenticated/settings/profile'
    | '/_authenticated/settings/security'
    | '/_authenticated/settings/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRouteRoute: typeof AuthenticatedRouteRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRouteRoute: AuthenticatedRouteRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/login"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated/route.tsx",
      "children": [
        "/_authenticated/",
        "/_authenticated/add",
        "/_authenticated/settings",
        "/_authenticated/sum"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index/route.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/add": {
      "filePath": "_authenticated/add/route.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/settings": {
      "filePath": "_authenticated/settings/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/settings/profile",
        "/_authenticated/settings/security",
        "/_authenticated/settings/"
      ]
    },
    "/_authenticated/sum": {
      "filePath": "_authenticated/sum/route.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/settings/profile": {
      "filePath": "_authenticated/settings/profile/route.tsx",
      "parent": "/_authenticated/settings"
    },
    "/_authenticated/settings/security": {
      "filePath": "_authenticated/settings/security/route.tsx",
      "parent": "/_authenticated/settings"
    },
    "/_authenticated/settings/": {
      "filePath": "_authenticated/settings/index.tsx",
      "parent": "/_authenticated/settings"
    }
  }
}
ROUTE_MANIFEST_END */
