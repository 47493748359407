import { createFileRoute, useRouter } from "@tanstack/react-router";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Label } from "@/components/ui/label.tsx";
import { ProfilePicture } from "@/components/ProfilePicture.tsx";
import { useMutation } from "@tanstack/react-query";
import { useId, useRef, useState } from "react";
import { customToast } from "@/hooks/use-toast.ts";
import { Spinner } from "@/components/Spinner.tsx";

export const Route = createFileRoute("/_authenticated/settings/profile")({
  component: RouteComponent,
});

type PictureState =
  | { state: "unknown" }
  | { state: "present"; src: string | undefined }
  | { state: "missing" };

function RouteComponent() {
  const router = useRouter();
  const { currentUser, supabase } = Route.useRouteContext({
    select: (it) => ({
      currentUser: it.session.user,
      supabase: it.supabase,
    }),
  });

  const [pictureState, setPictureState] = useState<PictureState>({
    state: "unknown",
  });
  const uploadForm = useRef<HTMLFormElement>(null);
  const deleteFormId = useId();

  const uploadMutation = useMutation({
    mutationFn: async (file: File) => {
      const { data, error } = await supabase.storage
        .from("profile-pictures")
        .upload(currentUser.id, file, {
          upsert: true,
        });

      if (!!error) {
        throw error;
      }

      return data;
    },
    onSuccess: async (data) => {
      console.log("Upload succeeded", data);

      uploadForm.current?.reset();

      customToast({
        outcome: "success",
        description: "Profilbild gespeichert",
      });

      if (pictureState.state === "present" && !!pictureState.src) {
        const cache = await caches.open("avatars");
        const response = await fetch(
          pictureState.src + "&nocache=" + new Date().getTime(),
          { mode: "cors", credentials: "omit", cache: "no-cache" }
        );
        await cache.put(pictureState.src, response);
      }

      window.location = window.location;
    },
    onError: async (error) => {
      console.error("Upload failed", error);

      uploadForm.current?.reset();

      customToast({
        outcome: "success",
        description: "Profilbild nicht gespeichert",
      });
    },
  });
  const deleteMutation = useMutation({
    mutationFn: async () => {
      const { data, error } = await supabase.storage
        .from("profile-pictures")
        .remove([currentUser.id]);

      if (!!error) {
        console.error("Failed to delete", error);
        throw error;
      }

      console.log("Deleted image", data);

      await router.navigate({ to: "." });

      if (pictureState.state === "present" && !!pictureState.src) {
        const cache = await caches.open("avatars");
        await cache.delete(pictureState.src);
      }

      window.location = window.location;
    },
    onSuccess: async (data) => {
      console.log("Delete succeeded", data);

      uploadForm.current?.reset();

      customToast({
        outcome: "success",
        description: "Profilbild gelöscht",
      });
    },
    onError: async (error) => {
      console.error("Delete failed", error);

      uploadForm.current?.reset();

      customToast({
        outcome: "fail",
        description: "Profilbild nicht gelöscht",
      });
    },
  });
  const changeNameMutation = useMutation({
    mutationFn: async (displayName: string) => {
      const { error } = await supabase.auth.updateUser({
        data: { display_name: displayName },
      });

      if (!!error) {
        throw error;
      }

      window.location = window.location;
    },
  });

  const deleting = deleteMutation.isPending;
  const uploading = uploadMutation.isPending;

  function uploadAvatar(fileList: FileList | null) {
    if (fileList === null || fileList.length === 0) {
      return;
    }

    console.log("Uploading", fileList);
    const file = fileList.item(0)!;
    uploadMutation.mutate(file);
  }

  return (
    <div className="grid gap-6">
      <Card>
        <CardHeader>
          <CardTitle>Profilbild</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-row items-center gap-4">
            <ProfilePicture
              size={64}
              userId={currentUser.id}
              shape="square"
              onLoaded={(src) => setPictureState({ state: "present", src })}
              onError={() => setPictureState({ state: "missing" })}
            />

            <form ref={uploadForm} onSubmit={(e) => console.log("Upload", e)}>
              <Button asChild>
                <label className="cursor-pointer" htmlFor="single">
                  {uploading ? "Uploading ..." : "Upload"}
                </label>
              </Button>
              <input
                style={{
                  visibility: "hidden",
                  position: "absolute",
                }}
                type="file"
                id="single"
                accept="image/*"
                multiple={false}
                onChange={(e) => uploadAvatar(e.target.files)}
                disabled={uploading}
              />
            </form>
            <form
              id={deleteFormId}
              onSubmit={(e) => {
                e.preventDefault();
                deleteMutation.mutate();
              }}
            >
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button
                    variant="destructive"
                    type="button"
                    disabled={pictureState.state !== "present"}
                  >
                    Löschen
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Löschen bestätigen</AlertDialogTitle>
                    <AlertDialogDescription>
                      Diese Aktion entfernt das Profilbild unwiderruflich.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Abbrechen</AlertDialogCancel>
                    <AlertDialogAction
                      type="submit"
                      className="bg-destructive hover:bg-destructive/90 text-destructive-foreground"
                      form={deleteFormId}
                    >
                      {deleting ? (
                        <span className="flex flex-row gap-1">
                          <Spinner size="small" />
                          Wird gelöscht ...
                        </span>
                      ) : (
                        "Löschen"
                      )}
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </form>
          </div>
        </CardContent>
      </Card>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const data = new FormData(e.currentTarget);
          const displayName = data.get("displayName")! as string;
          changeNameMutation.mutate(displayName);
        }}
      >
        <Card>
          <CardHeader>
            <CardTitle>Profil</CardTitle>
            <CardDescription>
              Hier kannst du dein Profil anpassen.
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col gap-4">
            <Label>Name</Label>
            <Input
              placeholder="Anzeigename"
              name="displayName"
              defaultValue={currentUser.user_metadata["display_name"]}
            />
          </CardContent>
          <CardFooter className="border-t px-6 py-4">
            <Button type="submit">Speichern</Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  );
}
