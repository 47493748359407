import { useRegisterSW } from "virtual:pwa-register/react";
import { RouterProvider } from "@tanstack/react-router";
import { router } from "@/router";
import { queryClient } from "@/utils/queryClient.ts";

export function App() {
  const sw = useRegisterSW();

  return (
    <RouterProvider
      router={router}
      context={{
        sw,
        queryClient,
      }}
    />
  );
}
